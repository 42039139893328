import { request, noTimeOutReq } from '@/utils/request.js'

// 查询开课计划过滤条件
export function getCoursePlanCondition () {
  return request({
    url: `/crs/open/plan/condition`,
    method: 'post',
  })
}


// 分页查询开课计划
export function getCoursePlan (pageIndex, pageSize, data) {
  return request({
    url: `/crs/open/plan/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}


// 提交开课计划
export function submitCoursePlan (crsOpenPlanId) {
  return request({
    url: `/crs/open/plan/submit/${crsOpenPlanId}`,
    method: 'post',
  })
}

// 课程安排
// 安排任课老师
// 查询开课计划
export function getCoursePlanLi (crsOpenPlanId) {
  return request({
    url: `/crs/open/plan/get/${crsOpenPlanId}`,
    method: 'post',
  })
}
// 查询教师列表
export function getTeacher (pageIndex, pageSize, crsOpenPlanId, data) {
  return request({
    url: `/crs/open/plan/teacher/arr/page/${crsOpenPlanId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
// 分页查询班级列表
export function getSchoolClass (pageIndex, pageSize, crsOpenPlanId, data) {
  return request({
    url: `/crs/open/plan/teacher/arr/page/class/${crsOpenPlanId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
// 查询开课计划教师安排
export function getCoursePlanTeacher (crsOpenPlanId) {
  return request({
    url: `/crs/open/plan/teacher/arr/list/${crsOpenPlanId}`,
    method: 'post',
  })
}
// 添加或更新开课计划教师安排
export function updateCoursePlanTeacher (crsOpenPlanId, data) {
  return request({
    url: `/crs/open/plan/teacher/arr/addOrUpdate/${crsOpenPlanId}`,
    method: 'post',
    data
  })
}



// 课程任务安排
// 课程列表
// 查询开课计划课程安排
export function getCoursePlanList (crsOpenPlanId) {
  return request({
    url: `/crs/open/plan/course/arr/list/${crsOpenPlanId}`,
    method: 'post'
  })
}
// 添加开课计划课程安排
export function addCoursePlan (crsOpenPlanId, data) {
  return request({
    url: `/crs/open/plan/course/arr/add/${crsOpenPlanId}`,
    method: 'post',
    data
  })
}
// 分页查询实验课程
export function getExpPagePlan (pageIndex, pageSize, sysOrgSchoolGradeId, data) {
  return request({
    url: `/crs/open/plan/course/arr/page/exp/${sysOrgSchoolGradeId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 课程任务列表
// 智能排课
// 查询课时规则安排
export function getClassHour (crsOpenPlanId) {
  return request({
    url: `/crs/open/rule/arr/get/lesson/${crsOpenPlanId}`,
    method: 'post',
  })
}
// 查询场地规则安排
export function getRuleTeachPlaceRoom (crsOpenPlanId) {
  return request({
    url: `/crs/open/rule/arr/get/TeachPlaceRoom/${crsOpenPlanId}`,
    method: 'post',
  })
}
// 删除场地规则安排
export function deleteRuleTeachPlaceRoom (crsOpenPlanId, data) {
  return request({
    url: `/crs/open/rule/arr/delete/TeachPlaceRoom/${crsOpenPlanId}`,
    method: 'post',
    data
  })
}
// 添加或更新课时规则安排
export function addOrUpdateClassHour (crsOpenPlanId, data) {
  return request({
    url: `/crs/open/rule/arr/addOrUpdate/lesson/${crsOpenPlanId}`,
    method: 'post',
    data
  })
}
// 添加场地规则安排
export function addRuleTeachPlaceRoom (crsOpenPlanId, data) {
  return request({
    url: `/crs/open/rule/arr/add/TeachPlaceRoom/${crsOpenPlanId}`,
    method: 'post',
    data
  })
}

// 自动排课
export function automaticPlan (crsOpenPlanId) {
  return request({
    url: `/crs/open/plan/arr/automatic/${crsOpenPlanId}`,
    method: 'post',
  })
}
// 添加排课
export function manualPlan (crsOpenPlanId, data) {
  return request({
    url: `/crs/open/plan/arr/manual/${crsOpenPlanId}`,
    method: 'post',
    data
  })
}
// 编辑排课
export function updatePlan (crsOpenPlanId, data, crsOpenPlanLessonId) {
  return request({
    url: `/crs/open/plan/arr/update/${crsOpenPlanId}/${crsOpenPlanLessonId}`,
    method: 'post',
    data
  })
}
// 删除排课
export function deletePlan (crsOpenPlanId, data) {
  return request({
    url: `/crs/open/plan/arr/delete/${crsOpenPlanId}`,
    method: 'post',
    data
  })
}
// 查询上课地点
export function getTeachPlaceRoom (crsOpenPlanId, sysSubjectDicId) {
  return request({
    url: `/crs/open/plan/arr/teachPlaceRoom/${crsOpenPlanId}/${sysSubjectDicId}`,
    method: 'post'
  })
}
// 分页查询排课结果
export function getPagePlan (pageIndex, pageSize, crsOpenPlanId) {
  return request({
    url: `/crs/open/plan/arr/page/${crsOpenPlanId}/${pageIndex}/${pageSize}`,
    method: 'post'
  })
}

// 课程表预览
// 获取课程表
export function getClassSchedule (timestamp,data) {
  return request({
    url: `/crs/open/plan/lesson/get/schedule/${timestamp}`,
    method: 'post',
    data
  })
}